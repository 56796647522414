import React from 'react';

export const IconRightArrow = ({ color }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" style={{ transform: 'rotate(180deg)' }}>
    <path d="m10.437 14.667 7.152-7.152-1.885-1.886L5.334 16l10.37 10.37 1.885-1.885-7.152-7.152h16.23v-2.666z" fill={ color } fillRule="evenodd" />
  </svg>
);

export const IconLeftArrow = ({ color }) => (
  <svg width="32" height="32" viewBox="0 0 32 32">
    <path d="m10.437 14.667 7.152-7.152-1.885-1.886L5.334 16l10.37 10.37 1.885-1.885-7.152-7.152h16.23v-2.666z" fill={ color } fillRule="evenodd" />
  </svg>
);

export const NavArrowDown = ({ color, svgClass }) => (
  <svg className={svgClass} width="24" height="24" viewBox="0 0 24 24">
    <path d="m7 10 5 5 5-5z" fill={ color } fillRule="evenodd"/>
  </svg>
);

export const IconLinkArrow = ({ color, svgClass }) => (
  <svg className={svgClass} width="24" height="24" viewBox="0 0 24 24">
    <path d="M14.904 5.94 13.49 7.354l3.643 3.644L4 10.999v2l13.137-.001-3.647 3.648 1.414 1.413 6.061-6.06z" fill={ color ? color : '#c8102e' } fillRule="evenodd"/>
  </svg>
);

export const IconUpLinkArrow = ({ color }) => (
  <svg width="26" height="27" viewBox="0 0 26 27" xmlns="http://www.w3.org/2000/svg">
    <path d="m6.214 10.962 1.48 1.48 3.812-3.812v13.74H13.6l-.001-13.744 3.816 3.815 1.478-1.479-6.34-6.34z" fill={ color ? color : '#c8102e' } fillRule="evenodd"/>
  </svg>
);

export const IconRightArrowSvgIcon = ({ color, ...props }) => {
  return (<svg width="32" height="32" viewBox="0 0 32 32" {...props} >
    <path d="M21.563 14.667 14.41 7.515l1.885-1.886L26.666 16l-10.37 10.37-1.885-1.885 7.152-7.152H5.333v-2.666z" fill={color} fillRule="evenodd"/>
  </svg>);
};

export const IconLeftArrowSvgIcon = ({ color, ...props }) => {
  return (<svg width="32" height="32" viewBox="0 0 32 32" {...props}>
    <path d="m10.437 14.667 7.152-7.152-1.885-1.886L5.334 16l10.37 10.37 1.885-1.885-7.152-7.152h16.23v-2.666z" fill={color} fillRule="evenodd" />
  </svg>);
};
