import React from 'react';
import { Button as MuiButton } from '@mui/material';
import { navigate } from 'gatsby';
import './styles.scss';

export function Button ({
  variant,
  startIcon,
  endIcon,
  className,
  children,
  to,
  target,
  onClick,
  ...props
}) {
  if (to && onClick) {
    console.error('Cannot use to and onClick together');
  }

  let href = null;

  if (/^(([a-z0-9]+:\/\/)|#)/i.test(to)) {
    href = to;
  } else if (to && !target) {
    onClick = (ev) => {
      ev.preventDefault();
      navigate(to);
    };
  } else if (to && target) {
    href = to;
  }

  return (
    <MuiButton
      variant={ variant }
      startIcon={ startIcon }
      endIcon={ endIcon }
      className={ `fwrd-button ${className ?? ''}` }
      disableRipple
      onClick={onClick}
      href={href}
      target={target}
      { ...props }
    >
      { children }
    </MuiButton>
  );
}
