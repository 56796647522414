/* eslint-disable react/jsx-handler-names */
import React from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import { Footer } from "../footer";
import Navigation from "../navigation";
import { useMergePrismicPreviewData } from "../../utils/use-merge-prismic-preview-data";
import "./index.scss";
import "../../components/testing-grid";

export default function Layout ({ data, pageContext, children }) {
  const _globalData = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }

      prismicGlobalOptions {
        _previewable
        data {
          siteTitle {
            text
          }
        }
      }
    }
  `);

  const globalData = useMergePrismicPreviewData(_globalData);

  const { siteTitle } = globalData.prismicGlobalOptions.data;

  const mainClassName = [ 'fwrd-body' ];

  if (data?.prismicHomepage) mainClassName.push('fwrd-homepage');
  if (data?.prismicPage) mainClassName.push('fwrd-page');
  if (data?.prismicArticles) mainClassName.push('fwrd-article');

  return (
    <>
      <Helmet
        titleTemplate={`${siteTitle.text} - %s`}
        defaultTitle={siteTitle.text}
      >
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1, user-scalable=no'
        />
        <meta name='theme-color' content='#400003' />
        <meta name='og:site_name' content={siteTitle.text} />
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/apple-touch-icon.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='/favicon-32x32.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='/favicon-16x16.png'
        />
        <link rel='manifest' href='/manifest.webmanifest' />
        <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#400003' />
        <meta name='msapplication-TileColor' content='#400003' />
      </Helmet>
      <main className={mainClassName.join(' ')}>
        <Navigation pageContext={pageContext} />
        {children}
      </main>

      <Footer />
    </>
  );
}
