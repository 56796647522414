import React from 'react';

export const IconCardFlag = () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z"/>
      <path d="M15.465 3H0v18h15.465a1 1 0 0 0 .832-.445l5.333-8a1 1 0 0 0 0-1.11l-5.333-8A1 1 0 0 0 15.465 3z" fill="#FFD900"/>
      <path fill="#101010" d="m9.005 14.456 2.624 1.379-.501-2.921 2.122-2.07-2.934-.426L9.005 7.76l-1.312 2.658-2.933.426 2.122 2.07-.5 2.92z"/>
    </g>
  </svg>
);
