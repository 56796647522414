/* eslint-disable max-len */
import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Img, LinkWrapper } from '@maxmedia/mxm-react-lib';
import { useMergePrismicPreviewData } from '../../utils/use-merge-prismic-preview-data';
import "./styles.scss";

export function Footer () {
  const _globalData = useStaticQuery(graphql`
    query FooterGlobalsQuery {
      prismicGlobalOptions {
        _previewable
        data {
          body {
            ... on PrismicGlobalOptionsDataBodyFooterColumn {
              id
              primary {
                text { text }
              }
              items {
                text
                link {
                  id
                  url
                  target
                  slug
                  raw
                }
              }
            }
          }
          footerChatbotIcon { gatsbyImageData }
          footerChatbotName
          footerPhoneNumber
          footerAddress {
            html
            raw
          }
          footerContactText {
            html
          }
          footerLoginLinks {
            link { url }
            text
          }
          footerLogo { alt, gatsbyImageData }
          footerPhoneIcon { gatsbyImageData }
          footerSocialLinks {
            link {
              url
              slug
            }
            icon {
              alt
              url
            }
            altIcon {
              alt
              url
            }
          }
          footerText { html }
        }
      }
    }
  `);

  const globalData = useMergePrismicPreviewData(_globalData);

  const {
    footerPhoneNumber, footerText, footerAddress, footerContactText
  } = globalData.prismicGlobalOptions.data;

  const renderListItems = (items) => {
    return items.map((item, i) => (
      <li key={i}><a href={item.link.url}>{item.text}</a></li>
    ));
  };

  let footerPhoneNumberLink = `tel:${footerPhoneNumber.replace(/[^0-9]/g, '')}`;

  return (
    <footer className={`fwrd-footer`}>
      <div className={`footer__inner container`}>
        <div className='footer-col footer-col-1'>
          <div className='content'>
            <Img
              image={globalData.prismicGlobalOptions.data.footerLogo} className={'footer__logo'}
            />
            <div
              dangerouslySetInnerHTML={{ __html: footerText.html }}
            />
          </div>
          <ul className='footer-links'>{
            globalData.prismicGlobalOptions.data.footerLoginLinks.map((link, index) => {
              return (
                <li key={index}>
                  <LinkWrapper url={ link.link.url }>{ link.text }</LinkWrapper>
                </li>
              );
            })
          }</ul>

          <div className='social-buttons'>{
            globalData.prismicGlobalOptions.data.footerSocialLinks.map((socialLink, index) => {
              return (
                <a key={index} href={socialLink.link.url}>
                  <img src={socialLink.icon.url} alt={socialLink.link.alt} />
                </a>
              );
            })
          }</div>
        </div>

        {
          globalData.prismicGlobalOptions.data.body.map((bodyData, index) => {
            return (
              <div key={index} className='footer-col footer-col-2 nav-padding'>
                <h4 className='footer-col__header'>{bodyData.primary.text.text}</h4>
                <ul className='footer-nav'>
                  {renderListItems(bodyData.items)}
                </ul>
              </div>
            );
          })
        }

        <div className='footer-col footer-col-5 nav-padding'>
          <div dangerouslySetInnerHTML={{ __html: footerContactText.html }} />
          <div className='contact-info'>
            <a href='#hellosunny'>
              <Img image={globalData.prismicGlobalOptions.data.footerChatbotIcon} alt="" />
              { globalData.prismicGlobalOptions.data.footerChatbotName }
            </a>
            <a href={footerPhoneNumberLink}>
              <Img image={globalData.prismicGlobalOptions.data.footerPhoneIcon} alt="" />
              <span>{footerPhoneNumber}</span>
            </a>
            <address dangerouslySetInnerHTML={{ __html: footerAddress.html }} />
          </div>
        </div>
      </div>
    </footer>
  );
}
