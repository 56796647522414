import React from 'react';
import { Switch, FormControlLabel } from '@mui/material';

export function InputSwitch ({
  label,
  ...props
}) {
  return (
    <FormControlLabel
      className="fwrd-form-input"
      label={label}
      control={
        <Switch
          inputProps={{ 'aria-label': label }}
          { ...props }
        />
      }
    />
  );
}
