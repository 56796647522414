import React, { forwardRef, useState } from 'react';
import { InputAdornment, FormControl, InputLabel, IconButton, FilledInput, FormHelperText } from '@mui/material';
import { Visibility, VisibilityOff, Error as ErrorIcon } from '@mui/icons-material';

export const TextInput = forwardRef(({
  label,
  id,
  disabled,
  error,
  helperText,
  className,
  endAdornment = null,
  ...props
}, ref) => {
  className = `
    fwrd-input
    ${disabled ? 'disabled' : ''}
    ${error ? 'error' : ''}
    ${helperText ? 'has-helper-text' : ''}
    ${className || ''}
  `;

  const errorAdornment = error ? (
    <InputAdornment position="end">
      <ErrorIcon />
    </InputAdornment>
  ) : null;

  return (
    <FormControl variant="filled" className={className}>
      <InputLabel htmlFor={id}>{ label }</InputLabel>
      <FilledInput
        id={id}
        label={label}
        inputProps={{ 'aria-label': label, ref }}
        error={error}
        { ...props }
        disabled={disabled}
        endAdornment={ errorAdornment ?? endAdornment}
      />
      { helperText ? <FormHelperText>{ helperText }</FormHelperText> : null}
    </FormControl>
  );
});

export const InputPassword = forwardRef(({
  label = 'Password',
  id = 'input-password',
  disabled,
  error,
  className,
  ...props
}, ref) => {
  const [ showPassword, setShowPassword ] = useState(false);

  const handleToggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  className = `
    fwrd-input
    ${disabled ? 'disabled' : ''}
    ${error ? 'error' : ''}
    ${className || ''}
  `;

  return (
    <FormControl variant="filled" className={className}>
      <InputLabel htmlFor={id}>{ label }</InputLabel>
      <FilledInput
        id={id}
        label={label}
        inputProps={{ 'aria-label': label, ref }}
        { ...props }
        type={showPassword ? 'text' : 'password'}
        disabled={disabled}
        error={error}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="Toggle password visibility"
              onClick={handleToggleShowPassword}
              onMouseDown={handleToggleShowPassword}
              edge="end"
              size="large">
              { showPassword ? <VisibilityOff /> : <Visibility /> }
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
});
