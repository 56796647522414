import React, { useMemo } from 'react';
import { Select, FormControl, MenuItem, InputLabel } from '@mui/material';

export function InputSelect ({
  options,
  value,
  handleChange,
  id = 'input-select',
  label = null
}) {
  const menuItemsRendered = useMemo(() => {
    if (!options || !options.length) return null;

    return options.map((option, i) => {
      const { label: optLabel, value: optValue } = option;

      return (
        <MenuItem
          key={`option-${i}`}
          value={optValue}
        >
          {optLabel}
        </MenuItem>
      );
    });
  }, [ options ]);

  return (
    <FormControl className="fwrd-select">
      {
        label ? (
          <InputLabel id={`${id}-label`}>{label}</InputLabel>
        ) : null
      }
      <Select
        labelId={`${id}-label`}
        id={id}
        value={value}
        onChange={handleChange}
      >
        { menuItemsRendered }
      </Select>
    </FormControl>
  );
}
