import React, { useCallback, useEffect, useRef, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { navigate } from 'gatsby';
import { LinkWrapper } from '@maxmedia/mxm-react-lib';
import gsap from 'gsap';
import { NavArrowDown, IconLeftArrow } from '../icons/arrows';
import './styles.scss';

const SubNavigation = ({
  pageTitle,
  parentTitle,
  parentLink,
  links,
  searchActive
}) => {
  const [ navOpen, setNavOpen ] = useState(false);
  const isDesktop = useMediaQuery('(min-width:1280px)');
  const navRef = useRef(null);

  useEffect(() => {
    if (navOpen) {
      gsap.to(navRef.current, { duration: 0.25, height: 'auto' });
    } else {
      gsap.to(navRef.current, { duration: 0.25, height: '0' });
    }
  }, [ navOpen ]);

  useEffect(() => {
    if (isDesktop && navOpen) setNavOpen(false);
    if (searchActive) setNavOpen(false);
  }, [ isDesktop, navOpen, searchActive ]);

  const handleNavToggle = useCallback(() => {
    if (isDesktop || !links.length) return;
    setNavOpen(!navOpen);
  }, [ navOpen, isDesktop, links ]);

  const renderNavIcon = () => {
    if (!links.length) return;

    return (
      <NavArrowDown svgClass={`sub-nav-indicator ${navOpen ? 'nav-active' : ''}`} />
    );
  };

  const handleLinkClick = () => {
    return (
      setNavOpen(false)
    );
  };

  const handleArrowClick = () => navigate(parentLink);

  const isTier3 = parentTitle && parentLink;

  const menuItems = links.map((link, i) => {
    const { text, url, target } = link;

    return (
      <li key={i} className="sub-nav-item">
        <LinkWrapper url={url} target={target} className="christopher" onClick={ handleLinkClick }>{text}</LinkWrapper>
      </li>
    );
  });

  function tier3Arrow (className) {
    if (!isTier3) return null;
    return (
      <div
        className={`tier-3-arrow ${className}`}
        role="button"
        tabIndex="0"
        onClick={handleArrowClick}
        onKeyDown={handleArrowClick}
      >
        <IconLeftArrow />
        <span className="parent-title">{ parentTitle }</span>
      </div>
    );
  }

  return (
    <div className={`fwrd-sub-navigation ${isTier3 ? `tier-3` : ''}`}>
      <div className={`sub-navigation__inner container ${navOpen ? 'nav-active' : ''}`}>
        {tier3Arrow('--mobile')}
        <div
          className="sub-nav-title"
          onClick={handleNavToggle}
          tabIndex="0"
          onKeyDown={handleNavToggle}
          role="button"
        >
          {tier3Arrow('--desktop')}
          {pageTitle}
          { renderNavIcon() }
        </div>
        <nav className="sub-nav" ref={navRef}>
          <ul className="sub-nav-list">
            { menuItems }
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default SubNavigation;
