import React from 'react';

export const LinkedInIcon = (color) => (
  <svg width="37" height="36" viewBox="0 0 37 36" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.654 30h4.955V14H6.654v16zm2.454-18.095c1.619 0 2.93-1.323 2.93-2.952C12.038 7.322 10.727 6 9.108 6c-1.62 0-2.93 1.322-2.93 2.953 0 1.63 1.31 2.952 2.93 2.952m10.422 9.696c0-2.25 1.035-3.589 3.019-3.589 1.822 0 2.697 1.287 2.697 3.59V30h4.932V19.87c0-4.286-2.43-6.358-5.824-6.358-3.395 0-4.824 2.643-4.824 2.643V14h-4.753v16h4.753v-8.399z" fill={ color } fillRule="evenodd"/>
  </svg>
);

export const TwitterIcon = (color) => (
  <svg width="37" height="36" viewBox="0 0 37 36" xmlns="http://www.w3.org/2000/svg">
    <path d="M31.676 10.171c-1.01.459-2.083.76-3.181.893a5.674 5.674 0 0 0 2.437-3.141 10.909 10.909 0 0 1-3.518 1.378c-1.714-1.88-4.448-2.343-6.66-1.129-2.214 1.214-3.354 3.802-2.779 6.307-4.454-.23-8.605-2.386-11.418-5.933a5.767 5.767 0 0 0-.75 2.855c0 1.969.978 3.708 2.464 4.726a5.419 5.419 0 0 1-2.508-.71v.07c0 2.703 1.859 5.032 4.444 5.568a5.445 5.445 0 0 1-2.504.098c.725 2.313 2.805 3.898 5.175 3.943a10.925 10.925 0 0 1-8.2 2.352A15.394 15.394 0 0 0 13.168 30c10.19 0 15.763-8.652 15.763-16.155 0-.245-.007-.492-.018-.733a11.413 11.413 0 0 0 2.765-2.94h-.002z" fill={ color } fillRule="evenodd"/>
  </svg>
);
