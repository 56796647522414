import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { Button } from '../button';
import { TextInput } from '../form-inputs';
import { CloseIcon } from '../icons';

export default function SearchDropdown ({ searchActive, setSearchActive }) {
  const [ searchVal, setSearchVal ] = useState('');

  const handleCloseClick = () => {
    setSearchActive(false);
  };

  const handleSearchSubmit = () => {
    if (!searchVal) return;
    navigate(`/search?q=${encodeURI(searchVal)}`);
  };

  return (
    <div className={`search-dropdown ${searchActive ? 'active' : ''}`}>
      <div className="search-dropdown__inner container">
        <div
          className="close-icon"
          role="button"
          tabIndex="0"
          onClick={handleCloseClick}
          onKeyDown={handleCloseClick}
        >
          <CloseIcon />
        </div>
        <TextInput
          label="Search Forward for news, jobs, shipments..."
          value={searchVal}
          onChange={(ev) => setSearchVal(ev.target.value)}
          id="search-input"
        />
        <div className="button-container">
          <Button
            variant="contained"
            onClick={handleSearchSubmit}
          >
            Search
          </Button>
        </div>
      </div>
    </div>
  );
}
