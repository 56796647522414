import React, { useCallback, useState } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { Img, LinkWrapper } from '@maxmedia/mxm-react-lib';
import { MenuIcon, LoginIcon, SearchIcon } from '../icons';
import SearchDropdown from './search-dropdown';
import SubNavigation from '../sub-navigation';
import { useMergePrismicPreviewData } from '../../utils/use-merge-prismic-preview-data';
import './styles.scss';

const USE_PARENT_SYM = Symbol.for('USE_PARENT');

function getSubnavLinks ({ link }) {
  return link.document.data.subnav?.map((subnavItem) => {
    if (!subnavItem.link.url) return null;
    if (!subnavItem.link.document && !subnavItem.titleOverride) return null;
    return {
      text: subnavItem.titleOverride || subnavItem.link.document?.data?.title?.text,
      url: subnavItem.link.url,
      target: subnavItem.link.target
    };
  })?.filter(d => d) || [];
}

function getSubnav ({ tier1Nav, subnav, title, url=null }, pageContext, searchActive) {
  if (!tier1Nav && !subnav) return null;

  for (let navItem of (tier1Nav ?? subnav)) {
    if (navItem.link.url === pageContext.url) {
      const subnavLinks = getSubnavLinks(navItem);

      if (!subnavLinks.length && subnav) {
        return {
          [USE_PARENT_SYM]: true,
          pageTitle: navItem.link.document.data.title.text
        };
      }
      return (
        <SubNavigation
          pageTitle={navItem.link.document.data.title.text}
          links={subnavLinks}
          parentTitle={title?.text}
          parentLink={url}
          searchActive={ searchActive }
        />
      );
    }

    if (navItem.link.document?.data) {
      const result = getSubnav({
        ...(navItem.link.document.data),
        url: navItem.link.url
      }, pageContext);

      if (result && result[USE_PARENT_SYM]) {
        return (
          <SubNavigation
            pageTitle={result.pageTitle}
            links={getSubnavLinks(navItem)}
            parentTitle={navItem.link.document.data.title.text}
            parentLink={navItem.link.url}
          />
        );
      }

      if (result) return result;
    }
  }

  return null;
}

export default function Navigation ({ pageContext }) {
  const [ searchActive, setSearchActive ] = useState(false);

  const _data = useStaticQuery(graphql`
    fragment navigationFragment on PrismicLinkType {
      url, target
      document {
        ... on PrismicPage {
          _previewable
          data {
            title { text }
            subnav {
              titleOverride
              link { url, target }
            }
          }
        }
        ... on PrismicResources {
          _previewable
          data {
            title { text }
          }
        }
      }
    }

    query Tier1NavigationQuery {
      prismicGlobalOptions {
        _previewable
        data {
          headerSearchText, headerLoginText

          headerLogo { gatsbyImageData, alt }

          headerLoginLink { url, target }

          tier1Nav {
            link {
              ...navigationFragment

              # tier
              document {
                ... on PrismicPage {
                  data {
                    subnav {
                      titleOverride
                      link {
                        ...navigationFragment

                        # tier 3
                        document {
                          ... on PrismicPage {
                            data {
                              subnav {
                                titleOverride
                                link {
                                  ...navigationFragment
                                }
                              }
                            }
                          }
                        }

                      }
                    }
                  }
                }
              }

            }
          }

        }
      }
    }
  `);

  const data = useMergePrismicPreviewData(_data);
  const {
    headerSearchText, headerLoginText, headerLoginLink, headerLogo, tier1Nav
  } = data.prismicGlobalOptions.data;

  const [ mobileNavActive, setMobileNavActive ] = useState(false);

  const handleToggleClick = useCallback(() => {
    setMobileNavActive(!mobileNavActive);
  }, [ mobileNavActive ]);

  const handleSearchClick = useCallback(() => {
    setMobileNavActive(false);
    setSearchActive(!searchActive);
  }, [ searchActive ]);

  const handleLinkClick = useCallback(() => {
    setMobileNavActive(false);
  }, []);

  const primaryNavLinks = tier1Nav.map(({ link }, i) => {
    const { url } = link;
    const { title } = link.document.data;

    return (
      <li key={i} className="primary-nav-list-item">
        <Link
          key={i}
          className="nav-link"
          to={ url }
          onClick={ handleLinkClick }
          tabIndex="0"
        >
          { title.text }
        </Link>
      </li>
    );
  });

  const navSpacerClassName = [ 'site-navigation-spacer' ];

  let subNavigation = getSubnav(pageContext);

  if (pageContext?.url) {
    subNavigation = getSubnav({ tier1Nav }, pageContext, searchActive);
  }

  if (subNavigation) {
    navSpacerClassName.push('--with-subnav');
  }

  return (
    <>
      <header className="site-navigation">
        <div className="site-nav__inner container">

          <div className="site-nav__left">
            <div
              className="mobile-toggle"
              onClick={handleToggleClick}
              onKeyDown={handleToggleClick}
              tabIndex="0"
              role="button"
            >
              <MenuIcon />
            </div>
            <LinkWrapper url="/" className="logo-container" title="Home">
              <Img image={headerLogo} />
            </LinkWrapper>
            {/* ERROR below: Non-interactive elements should not be assigned interactive roles */}
            <nav
              className={`primary-nav ${ mobileNavActive ? 'active' : '' }`}
              // onClick={ handleNavClose }
              // onKeyDown={ handleNavClose }
              // role="button"
              // tabIndex="0"
            >
              <ul className="nav-list primary-nav-list">
                { primaryNavLinks }
              </ul>
            </nav>
          </div>

          <div className="site-nav__right">
            <a
              role="button"
              className="usermenu-item nav-search"
              onClick={handleSearchClick}
              onKeyDown={handleSearchClick}
              tabIndex="0"
            >
              <SearchIcon />
              <span className="usermenu-title">{headerSearchText}</span>
            </a>
            <LinkWrapper
              url={headerLoginLink.url}
              className="usermenu-item nav-login"
              target={headerLoginLink.target}
            >
              <LoginIcon />
              <span className="usermenu-title">{headerLoginText}</span>
            </LinkWrapper>
          </div>

        </div>
        { subNavigation }
        <SearchDropdown searchActive={searchActive} setSearchActive={setSearchActive}/>
      </header>
      <div className={navSpacerClassName.join(' ')}></div>
    </>
  );
}
