/* eslint-disable react/jsx-max-depth */
import React from 'react';

export const TrackingActivityStart = () => (
  <svg width="15px" height="14px" viewBox="0 0 15 14" version="1.1">
    <title>Icon</title>
    <g id="Visual-Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Tracking-/-Details-/-Desktop-1280-1599" transform="translate(-524.000000, -915.000000)">
        <g id="1.-Component-/-Dialog-/-Modal-/-Form-/-Desktop" transform="translate(202.000000, 44.000000)">
          <g id="Content" transform="translate(40.000000, 48.000000)">
            <g id="Materials-Warning-Copy" transform="translate(8.000000, 721.000000)">
              <g id="Content" transform="translate(8.000000, 24.000000)">
                <g id="Activity" transform="translate(0.000000, 62.000000)">
                  <g id="Acivity-Data" transform="translate(266.372549, 0.000000)">
                    <g id="Icon" transform="translate(0.000000, 16.000000)">
                      <polygon id="Path" points="0 0 14 0 14 14 0 14"></polygon>
                      <path d="M12,1 L12,8 L7,13 L2,8 L2,1 L12,1 Z M10,3 L4,3 L4,7.15810061 L7,10.1581006 L10,7.15810061 L10,3 Z" id="Combined-Shape" fill="#C8102E"></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const TrackingActivity = () => (
  <svg width="15px" height="14px" viewBox="0 0 15 14" version="1.1">
    <title>Icon / Tool / Track</title>
    <g id="Visual-Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Tracking-/-Details-/-Desktop-1280-1599" transform="translate(-524.000000, -1065.000000)">
        <g id="1.-Component-/-Dialog-/-Modal-/-Form-/-Desktop" transform="translate(202.000000, 44.000000)">
          <g id="Content" transform="translate(40.000000, 48.000000)">
            <g id="Materials-Warning-Copy" transform="translate(8.000000, 721.000000)">
              <g id="Content" transform="translate(8.000000, 24.000000)">
                <g id="Activity" transform="translate(0.000000, 62.000000)">
                  <g id="Acivity-Data" transform="translate(266.372549, 0.000000)">
                    <g id="Origin-Search" transform="translate(0.000000, 16.000000)">
                      <g id="Icon-/-Tool-/-Track" transform="translate(0.000000, 150.000000)">
                        <polygon id="Path" points="0 0 14 0 14 14 0 14"></polygon>
                        <rect id="Rectangle" fill="#989A98" x="4" y="4" width="6" height="6" rx="3"></rect>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
