/*eslint-env browser*/
import React, { useEffect, useContext } from 'react';
import { PrismicPreviewProvider, PrismicContext } from 'gatsby-plugin-prismic-previews';
import markerSDK from '@marker.io/browser';
import 'gatsby-plugin-prismic-previews/dist/styles.css';

function MarkerIoLoader ({ destination }) {
  const [ context ] = useContext(PrismicContext);

  useEffect(() => {
    if (!destination) return;

    // let isOn = process.env.MARKER_IO === 'always';
    //
    // if (!isOn && process.env.MARKER_IO === 'prismic-preview') {
    //   isOn = context.previewState === 'ACTIVE';
    // }

    let isOn = true;

    if (isOn) {
      markerSDK.loadWidget({
        destination
      }).then((widget) => {
        window.widget = widget;
      });

      return () => {
        markerSDK.unload();
      };
    }
  }, [ destination, context ]);

  return null;
}

export function wrapRootElement ({ element }) {
  return (
    <>
      <MarkerIoLoader
        PrismicContext={PrismicContext}
        destination={process.env.GATSBY_MARKER_IO}
      />
      <PrismicPreviewProvider>
        {element}
      </PrismicPreviewProvider>
    </>
  );
}

export function onRouteUpdate () {
  if (process.env.GATSBY_DISABLE_LINKS) {
    setTimeout(() => {
      for (let aEl of document.querySelectorAll('a[href]')) {
        aEl.setAttribute('href', '#');
        const template = document.createElement('div');
        template.innerHTML = aEl.outerHTML;
        aEl.replaceWith(template.firstChild);
      }

      for (let aEl of document.querySelectorAll('button.MuiButton-root')) {
        const template = document.createElement('div');
        template.innerHTML = aEl.outerHTML;
        aEl.replaceWith(template.firstChild);
      }
    });
  }
}
