import React, { useMemo } from 'react';
import { FormControl, FormLabel, RadioGroup } from '@mui/material';
import { InputRadio } from './radio.js';

export function InputRadioGroup ({
  options = [],
  groupLabel = '',
  ...props
}) {
  const optionElements = useMemo(() => {
    if (!options.length) return null;

    return options.map((option, i) => {
      return (
        <InputRadio
          key={`input-radio-${i}`}
          { ...option }
        />
      );
    });
  }, [ options ]);

  return (
    <FormControl
      component="fieldset"
      className="fwrd-form-input"
    >
      <FormLabel component="legend" className="group-title">{groupLabel}</FormLabel>
      <RadioGroup
        aria-label={groupLabel}
        { ...props }
      >
        {optionElements}
      </RadioGroup>
    </FormControl>
  );
}
