import React from 'react';
import { FormControlLabel, Radio } from '@mui/material';

export function InputRadio ({
  label,
  value,
  ...props
}) {
  return (
    <FormControlLabel
      className="fwrd-form-input"
      value={value}
      control={<Radio />}
      label={label ?? value}
      { ...props }
    />
  );
}
